<template>
  <div class="users-create">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <div class="users-create-content page-layout">
      <UserForm
        ref="form"
        :oldUserData="oldUser"
        :userData="user"
        @change-force-leave="changeForceLeave"
      >
        <template #header>
          <PageHeader
            :has-back="true"
            :title="$t('create-user.title')"
            @back="goBack"
          />
        </template>
      </UserForm>

      <confirm-action-modal
        v-if="isConfirmModalOpen"
        :object-to-act-upon="{}"
        :text="confirmModalText"
        @close="isConfirmModalOpen = false"
        @confirm="onConfirmModal"
      />
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import UserForm from "./UserForm.vue";
import ForceLeaveMixin from "@/mixins/ForceLeaveMixin";
import HasBackMixin from "@/mixins/HasBackMixin";
import ConfirmActionModal from "../../components/modal/confirm-action.vue";
import PageHeader from "@/components/PageHeader";
import MeepIconSettings from "@/components/icons/MeepIconSettings";
import { USERS } from "@/constants";
import { mapGetters } from "vuex";

export default {
  name: "CreateUser",

  mixins: [ForceLeaveMixin, HasBackMixin],

  components: {
    Banner,
    UserForm,
    ConfirmActionModal,
    PageHeader,
    MeepIconSettings,
  },

  data() {
    return {
      isConfirmModalOpen: false,
      isAnyModified: false,
      user: {
        scope: this.isClient ? USERS.EMPLOYEE : null,
        gender: null,
        firstname: "",
        lastname: "",
        socialSecurityNumber: "",
        birthday: null,
        email: "",
        telfixe: "",
        telportable: "",
        adresse: "",
        city: "",
        country: "",
        codepostal: null,
        department: "",
        company_ids: [],
        hireDate: null,
        contractType: "",
        salary: null,
        trialPeriod: null,
      },
      oldUser: {},
    };
  },

  computed: {
    ...mapGetters(["isClient"]),

    confirmModalText() {
      switch (this.confirmationContext) {
        default:
          return {
            header: this.$t("users.view.modal-title"),
            body: () => {
              return this.$t("users.view.edit-modal-body");
            },

            question: this.$t("users.view.edit-modal-question"),
          };
      }
    },
  },

  methods: {
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },

    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },

    onConfirmModal() {
      switch (this.confirmationContext) {
        default:
          this.forceRouteLeave();
      }
    },

    changeForceLeave() {
      this.forceLeave = true;
    },
  },

  mounted() {
    this.$watch("$refs.form.isAnyModified", newValue => {
      this.isAnyModified = newValue;
    });

    this.oldUser = { ...this.user };
  },
};
</script>

<style lang="scss">
@import "../../styles/variable";

#app {
  .users-create {
    &__form.md-layout-item {
      padding-bottom: 60px;
    }

    .select-error {
      color: var(--primary, #ff1744);

      &--small {
        font-size: 12px;
      }
    }

    &__list-item {
      color: var(--bg-primary);
    }

    &__card {
      padding: 0 25%;

      @media (max-width: 600px) {
        padding: 0;
      }
    }

    .create-button {
      .md-button {
        padding: 0 30px;
      }
    }
  }
}
</style>
